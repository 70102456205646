.calculator {
  font-size: 3rem;
  min-height: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  font-size: inherit;
  font-family: inherit;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';
  width: min(50vw, 85rem);
  padding: 0.5em;
  border: none;
  border-radius: 25px;
  /* user-select: none; */ /* Buggy on safari! */
}

.input:focus {
  outline: none;
}

.result {
  color: var(--color-3);
  margin-top: 5rem;
  font-size: 4.5rem;
  min-height: 5rem;
  text-align: center;
  font-family: monospace;
}

/* Color Theme */
.input {
  color: var(--color-3);
  background: var(--color-2);
  box-shadow: var(--shadow-distance) var(--shadow-distance) calc(2 * var(--shadow-distance))
      var(--shadow-color-1),
    calc(-1 * var(--shadow-distance)) calc(-1 * var(--shadow-distance))
      calc(2 * var(--shadow-distance)) var(--shadow-color-2);
}

@media (max-width: 768px) {
  .input {
    width: 80vw;
  }
}
