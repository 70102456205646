:root {
  --color-1: #222831;
  --color-2: #393e46;
  --color-3: #eeeeee;
  --shadow-distance: 20px;
  --shadow-color-1: #1b2027;
  --shadow-color-2: #29303b;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-size: 1.6em;
}

.App {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-1);
}

.ribbon {
  padding: 0.6em 0;
  color: var(--color-1);
  text-decoration: none;
  /* https://www.freecodecamp.org/news/make-a-css-only-ribbon/ */
  position: fixed;
  background: var(--color-3);
  box-shadow: 0 0 0 999px var(--color-3);
  clip-path: inset(0 -100%);
  inset: 0 0 auto auto; /* top and right equal to 0 */
  transform-origin: 0 0; /* OR top left */
  transform: translate(29.3%) rotate(45deg);
}

@media (max-width: 768px) {
  .ribbon {
    display: none;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --color-1: #e0e0e0;
    --color-2: #e0e0e0;
    --color-3: #222831;
    --shadow-color-1: #b3b3b3;
    --shadow-color-2: #ffffff;
  }
}
